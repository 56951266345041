import { Controller } from "@hotwired/stimulus"

// Follow Omise payment amount
const MIN_PRICE = 0 // Handle in backend for NFC since it needs to get ticket type id from Eventpop first
const MAX_PRICE = 150_000.0
const LOCALE = "th-TH"
const NFC_UID_MIN_LENGTH = 0

export default class extends Controller {
  static targets = [
    "resellForm",
    "formReady",
    "inputState",
    "confirmState",
    // Input State
    "nfcUidInput",
    "priceInput",
    "priceHelperText",
    "imageInput",
    "changeImageBtn",
    "netPriceSummary",
    "nextStateButton",
    "wristbandRulesContainer",
    "wristbandRulesCheckbox",
    // Confirm State
    "termsContainer",
    "termsCheckbox",
    "submitFormButton",
  ]

  /**
   * This values parsing from controller @variables
   * orders - @eventpop_seller_orders
   * resellEvent - @resell_event
   * newTicket - @ticket
   */
  static values = {
    resellEvent: Object,
    newTicket: Object,
    platformFeePercentage: Number,
  }

  // Class Properties
  isAcceptableNFCUID = false
  resellPriceDecimal = 0.0
  netPriceDecimal = 0.0
  feeDecimal = 0.0

  // Lifecycle
  connect() {
    this.resellPriceDecimal = parseFloat(String(this.newTicketValue.price))
    this.feeDecimal = parseFloat(
      String(this.platformFeePercentageValue / 100)
    )

    this.setFormState()
  }

  // Dataflow Functions
  setFormState() {
    this.setSummarySection()
  }

  setSummarySection() {
    this.netPriceDecimal =
      this.resellPriceDecimal - this.resellPriceDecimal * this.feeDecimal
    this.netPriceSummaryTargets.forEach(target => {
      const options = {
        minimumFractionDigits: 2,
      }

      return (target.textContent = this.netPriceDecimal.toLocaleString(
        LOCALE,
        options
      ))
    })
  }

  checkFormValidity() {
    // Check form is valid
    if (
      this.isAcceptableNFCUID &&
      this.resellPriceDecimal >= MIN_PRICE &&
      this.resellPriceDecimal <= MAX_PRICE &&
      this.imageInputTargets.map(target => target.files.length > 0).every(Boolean) &&
      this.wristbandRulesCheckboxTarget.checked
    ) {
      this.nextStateButtonTarget.disabled = false
      this.nextStateButtonTarget.classList.remove("disabled")
      return true
    } else {
      this.nextStateButtonTarget.disabled = true
      this.nextStateButtonTarget.classList.add("disabled")
      return false
    }
  }

  // Action Functions
  handleNfcUidChange(event) {
    this.isAcceptableNFCUID = (event.target.value.length > NFC_UID_MIN_LENGTH)

    if (this.isAcceptableNFCUID) {
      this.nfcUidInputTarget.classList.remove("invalid")
    } else {
      this.nfcUidInputTarget.classList.add("invalid")
    }

    this.nfcUidInputTarget.value = event?.target?.value?.toUpperCase()
    this.checkFormValidity()
  }

  handlePriceChange(event){
    this.resellPriceDecimal = event.target.value

    if (event.target.value < 0) {
      this.priceInputTarget.value = MIN_PRICE
      this.resellPriceDecimal = MIN_PRICE
      return
    }

    if (
      event.target.value < MIN_PRICE ||
      event.target.value > MAX_PRICE ||
      isNaN(event.target.value)
    ) {
      this.priceInputTarget.classList.add("invalid")
      this.priceHelperTextTarget.classList.remove("hidden")
    } else {
      this.priceInputTarget.classList.remove("invalid")
      this.priceHelperTextTarget.classList.add("hidden")
    }

    this.setSummarySection()
    this.checkFormValidity()
  }

  handleFileChange() {
    this.checkFormValidity()
  }

  handleRulesCheckboxChange() {
    if (this.wristbandRulesCheckboxTarget.checked) {
      this.wristbandRulesContainerTarget.classList.replace("text-yellow-600", "text-green-600")
      this.wristbandRulesContainerTarget.classList.replace("bg-yellow-50", "bg-green-50")
      this.wristbandRulesContainerTarget.classList.replace("border-yellow-300", "border-green-300")
    } else {
      this.wristbandRulesContainerTarget.classList.replace("text-green-600", "text-yellow-600")
      this.wristbandRulesContainerTarget.classList.replace("bg-green-50", "bg-yellow-50")
      this.wristbandRulesContainerTarget.classList.replace("border-green-300", "border-yellow-300")
    }

    this.checkFormValidity()
  }

  handleTermsCheckboxChange() {
    this.submitFormButtonTarget.disabled = !this.termsCheckboxTarget.checked

    if (this.termsCheckboxTarget.checked) {
      this.submitFormButtonTarget.classList.remove("disabled")
    } else {
      this.submitFormButtonTarget.classList.add("disabled")
    }
  }

  // Handling State Functions
  enableInputs(mode) {
    const INPUT_TARGET = [this.nfcUidInputTarget, this.priceInputTarget]

    INPUT_TARGET.forEach(target => {
      target.disabled = mode === "add"
      target.classList[mode]("readonly")
    })

    // For Image Inputs
    this.imageInputTargets.forEach(target => {
      target.disabled = mode === "add"

      if (
        target.previousElementSibling &&
        target.previousElementSibling.hasAttribute("data-image-preview-target") &&
        target.previousElementSibling.getAttribute("data-image-preview-target") === "previewReady"
      ) {
        target.previousElementSibling?.classList[mode]("cursor-not-allowed", "hover:grayscale")
      }
    })

    this.changeImageBtnTargets.forEach(target => {
      target.disabled = mode === "add"
      target.classList[mode]("hidden")
    })
  }

  goToConfirmState() {
    if (!this.checkFormValidity()) return

    this.inputStateTarget.classList.replace("flex", "hidden")
    this.confirmStateTarget.classList.replace("hidden", "flex")
    this.enableInputs("add")
    this.wristbandRulesCheckboxTarget.disabled = true
    this.wristbandRulesCheckboxTarget.classList.add("text-stone-600")
    this.termsContainerTarget.scrollIntoView({ behavior: "smooth" })
  }

  goBackInputState() {
    this.confirmStateTarget.classList.replace("flex", "hidden")
    this.inputStateTarget.classList.replace("hidden", "flex")
    this.wristbandRulesCheckboxTarget.disabled = false
    this.wristbandRulesCheckboxTarget.classList.remove("text-stone-600")
    this.enableInputs("remove")
    // this.availableOrdersTarget.scrollIntoView({ behavior: "smooth" })
  }

  submitForm() {
    // Enable inputs before submit
    this.enableInputs("remove")
    this.priceInputTarget.value = this.resellPriceDecimal
    this.resellFormTarget.submit()
  }
}

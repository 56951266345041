<script lang="ts">
  import { onMount } from "svelte"
  import axios, { type CancelTokenSource } from "axios"
  import AccountRequirements from "./AccountRequirements.svelte"
  import { useI18n } from "$lib/i18n"

  const I18n = useI18n()
  const t = I18n.t.bind(I18n)

  let { currencies, formUrl, bankAccount } = $props()

  let selectedCurrency = $state(bankAccount?.wise_recipient_currency || "USD")
  let accountRequirements = $state([])
  let loading = $state(true)
  let valid = $state(false)
  let formData = $state(bankAccount?.wise_recipient_data || {})
  let cancelTokenSource: CancelTokenSource | undefined = $state()

  onMount(() => {
    handleCurrencyChange()
  })

  async function handleCurrencyChange() {
    loading = true
    cancelTokenSource?.cancel("Operation canceled due to new request")
    cancelTokenSource = axios.CancelToken.source()

    try {
      const response = await axios.get(
        `/profile/account/account_requirements?currency=${selectedCurrency}`,
        {
          cancelToken: cancelTokenSource.token,
        },
      )

      if (response.data && response.data.length) {
        accountRequirements = response.data
      }

      loading = false
    } catch (thrown) {
      if (axios.isCancel(thrown)) {
        console.log("Request canceled", thrown.message)
      } else {
        // handle error
      }
    }
  }

  let currentUser = {
    bank_account_verified: false, // Adjust this based on your actual data
  }

  async function onRefreshRequirement() {
    const response = await axios.post(
      "/profile/account/account_requirements",
      {
        currency: selectedCurrency,
        ...formData,
      },
      {
        headers: {
          "X-CSRF-Token": document
            .querySelector("meta[name='csrf-token']")
            ?.getAttribute("content"),
        },
      },
    )

    if (response.data && response.data.length) {
      accountRequirements = response.data
    }
  }

  async function handleSubmit(event) {
    event.preventDefault()
    if (!valid) return

    const response = await axios.request({
      url: formUrl,
      method: bankAccount ? "PUT" : "POST",
      data: {
        bank_account: {
          wise_recipient_data: formData,
          wise_recipient_currency: selectedCurrency,
        },
      },
      headers: {
        "X-CSRF-Token": document
          .querySelector("meta[name='csrf-token']")
          ?.getAttribute("content"),
      },
    })

    if (response.data && response.data.success) {
      window.location.href = response.data.redirect_url
    }
  }
</script>

{#if !currentUser.bank_account_verified}
  <!-- TODO: Use international warning -->

  <!-- <div
    class="w-full flex p-4 gap-2 text-sm text-yellow-800 bg-white border border-yellow-300 rounded-lg dark:bg-gray-800 dark:text-stone-400 dark:border-yellow-800"
    role="alert"
  >
    <i data-lucide="info" class="flex-shrink-0 inline w-4 h-4 mt-0.5"></i>
    <span class="sr-only">Notice Info</span>

    <div class="flex flex-col gap-2">
      <span class="text-yellow-600"
        >&ast; {I18n.t("helpers.form.verification_text")}</span
      >
      <span>
        &ast;&ast;
        {I18n.t("views.shared.bankbook_warning.line_1")}

        <span
          data-modal-target="payout-bank-support-modal"
          data-modal-toggle="payout-bank-support-modal"
          class="font-bold text-yellow-600 hover:underline hover:cursor-pointer"
        >
          {I18n.t("views.shared.bankbook_warning.line_2")}
        </span>

        {I18n.t("views.shared.bankbook_warning.line_3")}
      </span>
    </div>
  </div> -->
{/if}

<form
  onsubmit={handleSubmit}
  class="flex flex-col gap-4"
  method="POST"
  action={formUrl}
>
  <div class="flex flex-col gap-2">
    <label
      for="currency"
      class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
    >
      {t("helpers.form.choose_currency")}
    </label>
    <select
      id="currency"
      required
      bind:value={selectedCurrency}
      class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
      onchange={handleCurrencyChange}
    >
      <option value="" disabled>- Choose your currency -</option>
      {#each currencies as { code, name }}
        <option value={code}>{code} - {name}</option>
      {/each}
    </select>

    {#if loading}
      <div class="flex justify-center items-center my-8">
        <div class="animate-spin-slow">
          <svg
            class="w-6 h-6"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="2"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15"
            />
          </svg>
        </div>
      </div>
    {:else if accountRequirements.length}
      <AccountRequirements
        {accountRequirements}
        {onRefreshRequirement}
        bind:formData
        bind:valid
      />
    {:else}
      <p class="text-sm text-gray-500 dark:text-stone-400">
        No account requirements available for the selected currency.
      </p>
    {/if}
  </div>

  <hr class="w-full h-px bg-stone-300 border-0 dark:bg-stone-700" />

  <div class="flex flex-wrap-reverse sm:flex-nowrap sm:justify-between gap-4">
    <a class="btn w-full sm:w-auto" href="/profile/account">
      {t("routes.back")}
    </a>

    <button class="btn btn--brand w-full sm:w-auto">Submit</button>
  </div>
</form>
